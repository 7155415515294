import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AccountingReportApi {
  protected basePath = `${environment.API_V4}/accounting-report`;

  constructor(protected http: HttpClient) {}

  public agreementRevision() {
    return this.http.get<(string|number)[][]>(`${this.basePath}/agreement-revision`);
  }

  public userGrowth() {
    return this.http.get<number[][]>(`${this.basePath}/users-growth`);
  }
}
